<template>
  <nav class="nav-menu" :class="{ horizontal: isHorizontal }" @mouseleave="closeAllMenus">
    <ul>
      <li v-for="item in items" :key="item.id" class="menu-item-wrapper" @mouseleave="closeSubMenu(item)">
        <div
          @mouseenter="openSubMenu(item)"
          @click="handleMenuClick(item, true)"
          class="menu-item"
          :class="{ 'has-children': item.children, open: item.isOpen }"
        >
          <a class="label">{{ item.label }}</a>
          <span v-if="item.children" class="arrow">▼</span>
        </div>
        <recursive-menu
          v-if="item.children"
          :items="item.children"
          class="sub-menu"
          :class="{ open: item.isOpen }"
          :is-horizontal="isHorizontal"
          @close-parent="closeParentMenus"
          @menu-click="menuClick"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'RecursiveMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isHorizontal: this.isHorizontal,
    };
  },
  methods: {
    menuClick(item, flag) {
      if (!item.children) {
        if (flag) {
          this.closeParentMenus();
        }
        this.$emit('menu-click', item, false);
      }
    },
    handleMenuClick(item, flag) {
      if (!item.children) {
        if (flag) {
          this.closeParentMenus();
        }
        this.$emit('menu-click', item, true);
      } else {
        this.toggleSubMenu(item);
      }
    },
    openSubMenu(item) {
      if (this.isHorizontal && item.children) {
        this.$set(item, 'isOpen', true);
      }
    },
    findParentItem(item, items) {
      const parent = items.find((el) => el.id == item.parentId);
      if (parent) {
        this.findParentItem(parent, items);
      } else {
        this.closeSubMenu(item);
        return item;
      }
    },
    closeSubMenu(item) {
      if (this.isHorizontal && item.children) {
        this.$set(item, 'isOpen', false);
      }
    },
    toggleSubMenu(item) {
      if (item.children) {
        this.$set(item, 'isOpen', !item.isOpen);
      }
    },
    closeAllMenus() {
      this.closeMenuRecursively(this.items);
    },
    closeMenuRecursively(items) {
      items.forEach((item) => {
        if (item.children) {
          this.$set(item, 'isOpen', false);
          this.closeMenuRecursively(item.children);
        }
      });
    },
    checkScreenWidth() {
      this.isHorizontal = window.innerWidth >= 992;
    },
    flatten(data) {
      //{id, title, pid, children = []}，这段代码利用了对象的展开语法
      return data.reduce((arr, { children = [], ...args }) => arr.concat([{ ...args }], this.flatten(children)), []);
    },
    closeParentMenus() {
      this.$emit('close-parent');
      this.closeAllMenus();
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
};
</script>

<style scoped>
.nav-menu {
  font-family: Arial, sans-serif;
  /* width: 100%; */
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item-wrapper {
  position: relative;
}

.menu-item {
  cursor: pointer;
  padding: 10px;
  /* background-color: #f8f9fa; */
  /* border: 1px solid #e9ecef; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;
  white-space: nowrap;
}
/* .menu-item > .label {
  color: #ffffff;
}
.menu-item > .arrow {
  color: #ffffff;
} */

.menu-item:hover {
  background-color: #e9ecef;
}

.menu-item.has-children {
  font-weight: bold;
}

.arrow {
  font-size: 12px;
  transition: transform 0.3s ease;
  margin-left: 10px;
}

.menu-item.open > .arrow {
  transform: rotate(180deg);
}

.sub-menu {
  display: none;
  background-color: #ffffff;
  /* border: 1px solid #e9ecef; */
}

.sub-menu.open {
  display: block;
}

/* 垂直菜单样式 */
.nav-menu:not(.horizontal) .sub-menu {
  margin-left: 20px;
  margin-right: 20px;
}

/* 水平菜单样式 */
@media (min-width: 992px) {
  .nav-menu.horizontal > ul {
    display: flex;
  }

  .nav-menu.horizontal .menu-item-wrapper {
    position: relative;
  }

  .nav-menu.horizontal .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: max-content;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .nav-menu.horizontal .sub-menu .sub-menu {
    top: 0;
    left: 100%;
  }

  .nav-menu.horizontal .arrow {
    transform: rotate(-90deg);
  }

  .nav-menu.horizontal .menu-item:hover > .arrow,
  .nav-menu.horizontal .menu-item.open > .arrow {
    transform: rotate(0deg);
  }

  .nav-menu.horizontal .sub-menu .arrow {
    transform: rotate(-90deg);
  }

  .nav-menu.horizontal .sub-menu .menu-item:hover > .arrow,
  .nav-menu.horizontal .sub-menu .menu-item.open > .arrow {
    transform: rotate(0deg);
  }

  /* 确保所有子菜单都垂直显示 */
  .nav-menu.horizontal .sub-menu,
  .nav-menu.horizontal .sub-menu ul {
    display: none;
    flex-direction: column;
  }

  .nav-menu.horizontal .sub-menu.open,
  .nav-menu.horizontal .sub-menu.open ul {
    display: flex;
  }

  .nav-menu.horizontal .sub-menu .menu-item-wrapper {
    width: 100%;
  }
}
</style>
