import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
    ...['gaikuang','daolan','jiaotong','jingqu','jiangjie'].map(path => ({
      path: '/'+path,
      name: path,
      component: () => import('../views/gaikuang.vue'),
    })),
  {
    path:"/biz",
    name:"biz",
    component:()=>import('../views/biz.vue')
  },
  {
    path:"/culture",
    name:"culture",
    component:()=>import('../views/culture/culture.vue')
  },
  {
    path:"/vacation",
    name:"vacation",
    component:()=>import('../views/vacation.vue')
  },
  {
    path:"/news",
    name:"news",
    component :()=>import('../views/news/news.vue')
  },
  {
    path:"/list",
    name:"list",
    component:()=>import('../views/listView.vue')
  },
  {
    path:"/detail",
    name:"detail",
    component:()=>import('../views/listDetail.vue')
  },
  {
    path:"/hotel",
    name:"hotel",
    component:()=>import('../views/hotel/hotel.vue')
  },
  {
    path:"/contact",
    name:"contact",
    component:()=>import('../views/contact/contact.vue')
  },
  {
    path:"/shangye",
    name:"shangye",
    component:()=>import('../views/shangye/shangye.vue')
  },
  {
    path:"/links",
    name:"links",
    component:()=>import('../views/shangye/links.vue')
  },
  {
    path:"/jingqudongtai",
    name:"jingqudongtai",
    component:()=>import('../views/news/jingqudongtai.vue')
  },
  {
    path:"/jingdianjieshao",
    name:"jingdianjieshao",
    component:()=>import('../views/news/jingdianjieshao.vue')
  },
  {
    path:"/loving",
    name:"loving",
    component:()=>import('../views/loving/loving.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
