<script>
export default {
  name: "TitleView",
}
</script>

<template>
  <div class="title-view d-flex justify-content-center align-items-center mt-4">
<!--    <b-img src="../assets/logo.png" fluid alt="Responsive image"></b-img>-->
    <img alt="Vue logo" src="../assets/arrow.png">
    <h3 class="h5 font-weight-bold mt-auto mr-3 ml-3 text-primary"><slot></slot></h3>
    <img alt="Vue logo" src="../assets/arrow.png" class="flip-image">
  </div>
</template>

<style scoped lang="scss">
.flip-image {
  transform: scaleX(-1);
}
</style>
