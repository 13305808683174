<script>
export default {
  name: "loadingImage",
  props:{
    imgUrl:{
      type: String,
      default: ""
    },
  },
  data(){
    return {
      myStyle: {width: '1px', height: '1px'},
      showbg: true
    }
  },
  methods:{
    handleImageLoaded:function (event){
      if(event.target.src.endsWith('gif')){
        this.myStyle = {width: '1px', height: '1px'}
        this.showbg = true;
      }else{
        this.myStyle = {objectFit: 'cover',width: '100%',height: '100%'}
        this.showbg = false;
      }
    }
  },
}
</script>

<template>
  <div>
    <b-img-lazy class="bg position-absolute" blank-src="../assets/loading.gif" :style="myStyle" :src="imgUrl"
                @load.native="handleImageLoaded"></b-img-lazy>
    <div class="bg position-absolute d-flex justify-content-center align-items-center flex-wrap" v-if="showbg">
      <img src="../assets/loading.png" style="max-width: 100%"/><img src="../assets/loading.gif" style="width: 16px;height: 16px"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>