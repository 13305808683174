<script>

import TitleLeftView from "@/components/TitleLeftView.vue";
import ZoomAnimationView from "@/components/ZoomAnimationView.vue";

export default {
  name: "MyLink",
  components: {ZoomAnimationView, TitleLeftView},
  data() {
    return {
      linkInfo: undefined
    }
  },
  computed:{
    links(){
      if(!this.linkInfo)return []
      return (this.linkInfo.infos ?? []).sort((a,b)=>a.orderNum-b.orderNum)
    }
  },
  mounted() {
    this.getInfoByCode("links").then(linkInfo=>this.linkInfo=linkInfo)
  },
  methods:{
    openLink(item){
      if((item.url || "").length > 0)
        window.open(item.url)
    },
    gotoMore(){
      this.$router.push({ path: 'links', query: { code: 'links' }})
    },
  }
}
</script>

<template>

  <!--------------------------------------
  CARDS
  --------------------------------------->
  <section class="d-flex flex-column flex-lg-row pb-5 w-100 mb-0">
    <title-left-view class="col-lg-2" :show-more="false" @more="gotoMore">{{ linkInfo?.menuName }}</title-left-view>
    <div class="d-flex flex-wrap mt-5 p-md-1 col-lg-8" >
      <zoom-animation-view  class="col-6 col-lg-4 mb-3 c-pointer" v-for="item in links" :key="item.id">
        <div class="c-pointer text-center" @click="openLink(item)">
          <div v-if="(item.title || '').length > 0" class="placeholder w-100 bar-bg d-flex justify-content-center align-items-center text-dark shadow rounded-1" >
            <img v-if="(item.images || '').length > 0" class="mr-2" :src="item.images | imageUrl" :alt="item.title">{{item.title}}
          </div>
          <img v-else-if="(item.images || '').length > 0" :src="item.images | imageUrl" class="placeholder shadow rounded-1 w-100" :alt="item.title">
        </div>
      </zoom-animation-view>
    </div>
  </section>
</template>

<style scoped lang="scss">
.placeholder{
  aspect-ratio: 402 / 121;
}
img{

}
</style>
