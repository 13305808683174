<script>
import TitleView from "@/components/TitleView.vue";
import FlipAnimationView from "@/components/FlipAnimationView.vue";
import BackgroundView from "@/components/BackgroundView.vue";
import HomeImageIntroCell from "@/views/components/HomeImageIntroCell.vue";
import TitleLeftView from "@/components/TitleLeftView.vue";

export default {
  name: "HomeImageIntro",
  components: {TitleLeftView, HomeImageIntroCell, BackgroundView, FlipAnimationView, TitleView},
  data() {
    return {
      imgInfo: undefined
    }
  },
  computed:{
    images(){
      if(!this.imgInfo)return []
      return (this.imgInfo.infos ?? []).sort((a,b)=>a.orderNum-b.orderNum)
    }
  },
  mounted() {
    this.getInfoByCode("jingdianjieshao").then(imgInfo=>this.imgInfo=imgInfo)
  },
  methods:{
    openLink(item){
      if((item.url || "").length > 0)
        window.open(item.url)
    },
    gotoMore(){
      this.$router.push({ path: 'jingdianjieshao', query: { code: 'jingdianjieshao' }})
    }
  }
}
</script>

<template>

  <!--------------------------------------
  CARDS
  --------------------------------------->
  <section class="d-flex flex-column-reverse justify-content-end flex-lg-row pt-lg-4 w-100">
    <div class="d-flex flex-wrap justify-content-end mt-5 p-md-4 flex-grow-1 col-lg-9" >
      <div class="col1-md d-flex flex-column mb-2">
        <home-image-intro-cell class="pb-2" :aspect-ratio="360/425" v-if="images.length > 0" :item="images[0]"></home-image-intro-cell>
        <home-image-intro-cell :aspect-ratio="360/248" v-if="images.length > 1" :item="images[1]"></home-image-intro-cell>
      </div>
      <div class="col2-md mr-lg-2 ml-lg-2 mb-2">
        <home-image-intro-cell :aspect-ratio="479/682" v-if="images.length > 2" :item="images[2]"></home-image-intro-cell>
      </div>
      <div class="col3-md d-flex flex-column">
        <home-image-intro-cell class="pb-2" :aspect-ratio="430/248" v-if="images.length > 3" :item="images[3]"></home-image-intro-cell>
        <home-image-intro-cell :aspect-ratio="430/423" v-if="images.length > 4" :item="images[4]"></home-image-intro-cell>
      </div>
    </div>
    <title-left-view class="col-lg-2 pl-4 pl-lg-0" show-right-bg direction="row" top="20%" @more="gotoMore">{{ imgInfo?.menuName }}</title-left-view>
  </section>
</template>

<style scoped lang="scss">
.col1-md,.col2-md,.col3-md {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 992px) {
  .col1-md {
    -ms-flex: 0 0 percentage(360/1304) !important;
    flex: 0 0 percentage(360/1304) !important;
    max-width: percentage(360/1304) !important;
  }
  .col2-md {
    -ms-flex: 0 0 percentage(479/1304) !important;
    flex: 0 0 percentage(479/1304) !important;
    max-width: percentage(479/1304) !important;
  }
  .col3-md {
    -ms-flex: 0 0 percentage(430/1304) !important;
    flex: 0 0 percentage(430/1304) !important;
    max-width: percentage(430/1304) !important;
  }
}
</style>
