<script>
import BackgroundView from "@/components/BackgroundView.vue";
import ZoomAnimationView from "@/components/ZoomAnimationView.vue";

export default {
  name: "MyFooter",
  components: { ZoomAnimationView, BackgroundView },
  computed: {
    logoUrl() {
      if (this.logo && (this.logo.images || "").length > 0) {
        // eslint-disable-next-line no-undef
        return (typeof apiUrl != 'undefined' ? apiUrl : "http://192.168.1.18:80/dev-api") + this.logo.images
      }
      return '/assets/logo.png'
    },
    links() {
      if (!this.linkInfo) return []
      return (this.linkInfo.infos ?? []).sort((a, b) => a.orderNum - b.orderNum)
    },
    footerInfos() {
      if (!this.footerInfo) return []
      return (this.footerInfo.infos ?? []).sort((a, b) => a.orderNum - b.orderNum)
    }
  },
  data() {
    return {
      logo: {},
      linkInfo: undefined,
      footerInfo: undefined
    };
  },
  mounted() {
    this.getMenusByCode("logo").then(logo => this.logo = logo)
    this.getInfoByCode("qr").then(linkInfo => this.linkInfo = linkInfo)
    this.getInfoByCode("yejiao").then(footerInfo => this.footerInfo = footerInfo)
  },
  methods: {
    openLink(item) {
      if ((item.url || "").length > 0)
        window.open(item.url)
    }
  }
}
</script>

<template>
  <div class="full-width text-center">

    <!--------------------------------------
    FOOTER
    --------------------------------------->
    <background-view class="pt-5">
      <footer class="pb-5">
        <div class="container">
          <div class="d-flex flex-wrap justify-content-center justify-content-lg-between">
            <div class="col-12 col-lg-6 col-md logo-block text-left flex-shrink-0" style="padding: 0px;">
              <b-navbar-brand class="c-pointer logo">
                <img alt="logo" :src="logoUrl">
              </b-navbar-brand>
              <ul class="text-white mt-3 pl-0">
                <li class="d-flex" v-for="item in footerInfos" :key="item.id">
                  <div style="flex-basis: 6em" class="flex-shrink-0 mr-1">{{ item.title }}：</div>
                  <div class="text-wrap">{{ item.remark }}</div>
                </li>
                <!--                <li class="d-flex">-->
                <!--                  <div style="flex-basis: 6em" class="flex-shrink-0">咨询电话：</div>-->
                <!--                  <div class="text-wrap">0379-60696969</div>-->
                <!--                </li>-->
                <!--                <li class="d-flex">-->
                <!--                  <div style="flex-basis: 6em" class="flex-shrink-0">地理位置：</div>-->
                <!--                  <div class="text-wrap">河南省洛阳市老城区</div>-->
                <!--                </li>-->
                <!--                <li class="d-flex">-->
                <!--                  <div style="flex-basis: 6em" class="flex-shrink-0">公交线路：</div>-->
                <!--                  <div class="text-wrap">洛阳火车站到洛邑古城直达公交为206路</div>-->
                <!--                </li>-->
                <!--                <li class="d-flex">-->
                <!--                  <div style="flex-basis: 6em" class="flex-shrink-0">开车自驾：</div>-->
                <!--                  <div class="text-wrap">打开百度 / 高德 / 腾讯地图App软件搜索洛阳洛邑古城即可</div>-->
                <!--                </li>-->
              </ul>
            </div>
            <div
              class="col-6 col-lg-1 p-1 col-md d-flex flex-column align-items-center justify-content-center c-pointer"
              v-for="item in links" :key="item.id" @click="openLink(item)">
              <img :alt="item.title" :src="item.images | imageUrl" class="rounded-half w-100">
              <div class="mb-4 text-white mt-3 name">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </footer>
    </background-view>
    <div class="line w-100"></div>
    <background-view>
      <div class="col-md position-relative p-5">
        <span class="mb-4 text-white copy-right" v-html="$t('copyright')"></span>
      </div>
    </background-view>
  </div>
</template>

<style scoped lang="scss">
.line {
  height: 1px;
}

.logo-block {
  flex-basis: auto;
  line-height: 2em;
}
.name{
  height: 2rem;
}
@media (min-width: 992px) {
  .logo-block {
    flex-basis: 35em;
  }
}
@media (max-width: 992px) {
  ::v-deep {
    .logo img {
      width: 130px;
    }
  }
}
@media (min-width: 992px) {
  ::v-deep {
    .logo img {
      height: 28px;
    }
  }
}
</style>
