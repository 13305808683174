<script>
export default {
  name: "FlipAnimationView",
  data() {
    return {
      isFlipped: false
    }
  },
  methods: {
    flip() {
      this.isFlipped = true;
    },
    unflip() {
      this.isFlipped = false;
    }
  }
}
</script>

<template>
  <div class="flip-container" :class="{ flipped: isFlipped }" @mouseenter="flip" @mouseleave="unflip">
    <div class="flipper">
      <div class="front">
        <slot name="front">
          <h2>正面内容</h2>
        </slot>
      </div>
      <div class="back">
        <slot name="back">
          <h2>背面内容</h2>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flip-container {
  perspective: 1000px;
  display: inline-block;

  &.flipped {
    .flipper {
      transform: rotateY(180deg);
    }
  }
}

.flipper {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.front, .back {
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front {
  background-color: #f1f1f1;
}

.back {
  background-color: #e1e1e1;
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
