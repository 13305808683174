import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import { VBHoverPlugin } from 'bootstrap-vue'
Vue.use(VBHoverPlugin)
Vue.use(BootstrapVue)
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
};


Vue.use(Toast, options);