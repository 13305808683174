import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/axios'
import './plugins/extension'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
  duration: 1000,
  easing: 'ease-in-out-back',
})
import './assets/scss/app.scss'
import i18n from './i18n'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.imgMaxHeight = window.innerHeight
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')

