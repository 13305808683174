"use strict";

import Vue from "vue";
import * as dayjs from 'dayjs'
const MyPlugin = {}
MyPlugin.install = function(Vue) {
    Vue.axios = dayjs;
    window.axios = dayjs;
    Object.defineProperties(Vue.prototype, {
        dayjs: {
            get() {
                return dayjs;
            }
        },
        $dayjs: {
            get() {
                return dayjs;
            }
        },
    });
    Vue.mixin({
        methods: {
            async getInfos(ids){
                return (await Promise.all(ids.map(async e=>((await this.$axios.get('/website/menu/getHomeMenuInfo', {params : {homeMenuId: e}})).data.data)))).flat()
            },
            async getInfoByCode(homeMenuCode){
                const res = await this.$axios.get('/website/menu/getHomeMenuInfo', {params : {homeMenuCode}})
                return res.data.data &&  Array.isArray(res.data.data) && res.data.data.length > 0 ? res.data.data[0] : res.data.data
            },
            async getInfo(id){
                const res = await this.$axios.get('/website/menu/getHomeMenuInfo', {params : {homeMenuId:id}})
                return res.data.data &&  Array.isArray(res.data.data) && res.data.data.length > 0 ? res.data.data[0] : res.data.data
            },
            async getMenusByCode(menuCode) {
                const res = (await this.$axios.get('/website/menu/menuTree', {params : {menuCode}}))
                return  res.data.data &&  Array.isArray(res.data.data) && res.data.data.length > 0 ? res.data.data[0] : undefined
            },
            async getMenusById(id) {
                const res = (await this.$axios.get('/website/menu/menuTree', {params : {id}}))
                return  res.data.data &&  Array.isArray(res.data.data) && res.data.data.length > 0 ? res.data.data[0] : undefined
            }
        },
        filters: {
            imageUrl(value) {
                if (value) {
                    if(value.startsWith('http'))return value;
                    // eslint-disable-next-line no-undef
                    return (typeof apiUrl != 'undefined' ? apiUrl : "http://192.168.1.18:80/dev-api") + value;
                }
            }
        }
    });
};

Vue.use(MyPlugin)

export default MyPlugin;
