import { render, staticRenderFns } from "./FlipAnimationView.vue?vue&type=template&id=72f23f91&scoped=true"
import script from "./FlipAnimationView.vue?vue&type=script&lang=js"
export * from "./FlipAnimationView.vue?vue&type=script&lang=js"
import style0 from "./FlipAnimationView.vue?vue&type=style&index=0&id=72f23f91&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f23f91",
  null
  
)

export default component.exports