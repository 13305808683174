<script>
import BackgroundView from "@/components/BackgroundView.vue";
import FlipAnimationView from "@/components/FlipAnimationView.vue";

export default {
  name: "HomeImageIntroCell",
  components: {FlipAnimationView, BackgroundView},
  props: {
    item: {
      type: Object,
      default: undefined
    },
    aspectRatio: {
      type: Number,
      default: 1
    }
  },
  methods: {
    gotoLink(item){
      if((item.url || "").length > 0)
        window.open(item.url)
      else this.$router.push({
        name: 'detail',
        query: { id: item.id },
      });
    }
  }
}
</script>

<template>
  <flip-animation-view class="w-100 c-pointer">
    <template #front>
      <div :style="{aspectRatio}" class="w-100" @click="gotoLink(item)">
        <img class="card-img-top" :style="{aspectRatio}" :src="item.images|imageUrl"  :alt="item.title" />
        <div class="img-bottom-info text-white text-center p-2 h4 m-0">{{ item.title }}</div>
      </div>
    </template>
    <template #back>
      <background-view class="w-100" :style="{aspectRatio}">
        <template #bg>
          <img class="card-img-top" :src="item.images|imageUrl" :style="{aspectRatio}" :alt="item.title"  @click="gotoLink(item)"/>
        </template>
        <div class="d-flex flex-column w-100 h-100 text-white justify-content-center align-items-center z-index-1 position-absolute" @click="gotoLink(item)">
          <h5 class="text-white h4 m-0">{{ item.title }}</h5>
          <div class="divider bg-light rounded mb-4"></div>
          <div class="content text-small m-0">
            <span v-if="(item.otherInfo || '').indexOf('intro') >= 0">{{JSON.parse(item.otherInfo).find(e=>e.label == 'intro').value}}</span>
          </div>
        </div>
      </background-view>
    </template>
  </flip-animation-view>
</template>

<style scoped lang="scss">
@import "../../assets/scss/app.scss";
@import "../../assets/scss/bootstrap-user-variables";
.img-bottom-info{
  opacity: .7;
  position: absolute;
  background-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
}
.content{
  max-height: 70%;
  overflow-y: auto;
  width: 80%;
}
.divider{
  height: 2px;
  width: 1em;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  background-color: $paleBlue;
  opacity: 0.5;
  height: 50px;
  outline-offset: -2px;
  -webkit-border-radius: 4px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff0;
  -webkit-border-radius: 0;
}
</style>
