<template>
  <div class="home">
<!--    <img alt="天气预报" src="/assets/img/demo/1.jpg" class="floatImage visible-lg">-->
    <home-banner></home-banner>
<!--    <my-header></my-header>-->
<!--    <my-card></my-card>-->
    <home-news-intro></home-news-intro>
    <home-open-intro></home-open-intro>
    <home-map-intro></home-map-intro>
    <home-image-intro></home-image-intro>
    <!-- <my-link></my-link> -->
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader.vue";
import MyCard from "@/components/MyCard.vue";
import HomeBanner from "@/components/HomeBanner.vue";
import HomeImageIntro from "@/components/HomeImageIntro.vue";
import MyLink from "@/components/MyLink.vue";
import HomeMapIntro from "@/components/HomeMapIntro.vue";
import HomeOpenIntro from "@/components/HomeOpenIntro.vue";
import HomeNewsIntro from "@/components/HomeNewsIntro.vue";

export default {
  name: 'HomeView',
  components: {
    HomeNewsIntro,
    HomeOpenIntro,
    HomeMapIntro,
    MyLink,
    HomeImageIntro,
    HomeBanner,
    MyCard,
    MyHeader,
  }
}
</script>
<style scoped lang="scss">
//::v-deep section{
//  margin: 4em 0;
//}
//.floatImage{
//  display: none;
//  position: fixed;
//  width: 120px;
//  height: 120px;
//  top: 0px;
//  right: 20px;
//  z-index: 99999;
//}
//@media (min-width: 1440px) {
//  .visible-lg{
//    display: block;
//  }
//}
</style>
