<script>
export default {
  name: "MyCard"
}
</script>

<template>

  <!--------------------------------------
  CARDS
  --------------------------------------->
  <section class="pt-4 pb-5">
    <h3 class="h5 mb-4 font-weight-bold">Cards</h3>
<!--    <div class="row">-->
<!--      <div class="col-lg-6">-->
<!--        <div class="card bg-dark overlay overlay-black text-white shadow-sm border-0">-->
<!--          <img class="card-img" src="/assets/img/demo/7.jpg" alt="Card image">-->
<!--          <div class="card-img-overlay d-flex align-items-center text-center">-->
<!--            <div class="card-body">-->
<!--              <h3 class="card-title">Overlay center align</h3>-->
<!--              <p class="card-text text-muted">-->
<!--                With supporting text below as a natural lead-in to additional content.-->
<!--              </p>-->
<!--              <a href="#" class="btn btn-info btn-round">Do anything</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-6">-->
<!--        <div class="card bg-dark overlay overlay-blue text-white shadow-sm border-0 mt-sm-4 mt-lg-0">-->
<!--          <img class="card-img" src="/assets/img/demo/9.jpg" alt="Card image">-->
<!--          <div class="card-img-overlay d-flex align-items-center text-center">-->
<!--            <div class="card-body">-->
<!--              <h3 class="card-title">Special title treatment</h3>-->
<!--              <p class="card-text">-->
<!--                Perhaps you need a button-->
<!--              </p>-->
<!--              <a href="#" class="btn btn-purple btn-round">Go somewhere</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="d-flex flex-wrap mt-5" data-aos="fade-up"
         data-aos-anchor-placement="center-bottom"
         data-aos-delay="300">
      <div class="col-md-4">
        <div class="card shadow-sm border-0">
          <img class="card-img-top" src="/assets/img/demo/blog8.jpg" alt="Card image cap">
          <div class="card-body text-center">
            <img class="rounded-circle shadow mt-neg5" src="assets/img/demo/avatar.png" width="70">
            <h5 class="card-title">Sal</h5>
            <p class="card-text text-muted">
              Hi, I am the author of Anchor Bootstrap UI Kit @wowthemesnet.
            </p>
            <div class="text-center">
              <a target="_blank" href="https://facebook.com/wowthemesnet/"><span class="iconbox iconsmall rounded-circle bg-secondary text-white mr-1"><i class="fab fa-facebook-f"></i></span></a>
              <a target="_blank" href="https://twitter.com/wowthemesnet"><span class="iconbox iconsmall rounded-circle bg-info text-white mr-1"><i class="fab fa-twitter"></i></span></a>
              <a target="_blank" href="https://github.com/wowthemesnet"><span class="iconbox iconsmall rounded-circle bg-dark text-white"><i class="fab fa-github"></i></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow-sm border-0">
          <div class="date">
            <div class="day">
              27
            </div>
            <div class="month">
              Mar
            </div>
          </div>
          <img class="card-img-top" src="/assets/img/demo/3.jpg" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">Summer holiday is the best</h5>
            <p class="card-text text-muted">
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </p>
            <a href="#" class="btn btn-purple btn-round">View More</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow-sm border-0">
          <img class="card-img-top" src="/assets/img/demo/2.jpg" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">Just a card</h5>
            <p class="card-text text-muted">
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </p>
            <a href="#" class="btn btn-info btn-round">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
