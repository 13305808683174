<script>
export default {
  name: "ZoomAnimationView",
}
</script>

<template>
  <div class="zoom-image">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.zoom-image {
  transition: transform 0.3s ease; /* 添加过渡效果 */
  z-index: unset;
}

.zoom-image:hover {
  transform: scale(1.2); /* 鼠标悬停时放大20% */
  z-index: 3;
}
</style>
