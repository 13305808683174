<template>
  <div>
    <slot></slot>
    <!-- Search Overlay -->
    <div to="body" class="overlay" @click="toggleOverlay" v-show="showOverlay">
      <div class="search-container" @click.stop>
        <h2>搜索</h2>

        <div class="input-group">
          <input type="text" class="form-control" placeholder="输入搜索内容" aria-label="Input group example" aria-describedby="btnGroupAddon">
          <div class="input-group-append">
            <div class="input-group-text c-pointer" id="btnGroupAddon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg></div>
          </div>
        </div>
        <div class="suggestions">
          <h3>常用搜索</h3>
          <ul>
            <li>搜索建议1</li>
            <li>搜索建议2</li>
            <li>搜索建议3</li>
            <!-- 更多搜索建议可以在这里添加 -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchOverlay',
  data() {
    return {
      showOverlay: false,
    };
  },
  methods: {
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保蒙版在页面最上层 */
}

.search-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  z-index: 1001; /* 确保搜索容器在蒙版之上 */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ccc; /* 可以根据需要调整颜色 */
}

.suggestions {
  margin-top: 20px;
}

.suggestions ul {
  list-style: none;
  padding: 0;
}

.suggestions li {
  margin-bottom: 5px;
}
</style>
