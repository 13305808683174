<script>
export default {
  name: "BackgroundView",
  props: {
    type: {
      type: String,
      default: "dark",
    },
    backgroundColor: String,
    zIndex: Number
  },
  computed: {
    classes(){
      const dic = {};
      dic[this.type] = true;
      return dic;
    }
  }
}
</script>

<template>
  <div class="background-view">

    <div class="bg">
      <slot name="bg"></slot>
    </div>
    <div :class="{'nav-bg':true, ...classes}" :style="backgroundColor||zIndex ? {backgroundColor,zIndex} : {}"></div>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/app.scss";
.background-view{ position: relative; }
.nav-bg{
  opacity: .7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -10;
}
.bg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -21;
}
.primary{
  background-color: $paleBlue;
}
.dark{
  background-color: #000;;
}
.nav-bg-opacity{
  opacity: 1;
}
@media(min-width: 992px){
  .nav-bg-opacity{
    opacity: .7;
  }
}
</style>
