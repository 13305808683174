<template>
  <div class="LaunchOverlay">
    <div class="logo">
      <img :src="logoUrl" title="" alt="">
    </div>
    <video v-if="isVideo" class="background-media" autoplay loop muted playsinline>
      <source :src="mediaSource" type="video/mp4">
    </video>
    <img v-else class="background-media" :src="mediaSource" alt="Background Image">
    <div class="g-btn">
      <div class="bg"></div>
      <a class="text text-center">向下滚动<br />进入网站</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LaunchOverlay',
  props: {
    mediaSource: {
      type: String,
      required: true
    },
    isVideo: {
      type: Boolean,
      default: false
    },
    scrollThreshold: {
      type: Number,
      default: 400
    },
    showOnce: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHidden: false,
      logo: undefined,
      showTime: 0
    };
  },
  computed: {
    logoUrl() {
      if (this.logo && (this.logo.images || '').length > 0) {
        // eslint-disable-next-line no-undef
        return (typeof apiUrl != 'undefined' ? apiUrl : 'http://192.168.1.18:80/dev-api') + this.logo.images;
      }
      return '/assets/logo.png';
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.getMenusByCode('logo').then((logo) => (this.logo = logo));
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > this.scrollThreshold) {
        if (!this.isHidden) {
          this.isHidden = true;
          this.$el.style.opacity = 0;
          this.$el.style.zIndex = -1;
        }
      } else {
        if (this.isHidden && !this.showOnce) {
          this.isHidden = false;
          this.$el.style.opacity = 1;
          this.$el.style.zIndex = 100001;
        }
      }
    }
  }
};
</script>

<style scoped>
@keyframes rot1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.LaunchOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transition: opacity 0.4s ease;
  z-index: 100001;
}
.LaunchOverlay .logo {
  width: 211px;
  height: 73px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 60px;
  z-index: 2;
}
.LaunchOverlay .g-btn {
  font-size: 30px;
  color: #fff;
  width: 318px;
  height: 272px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 172px;
  z-index: 2;
}
/* //.LaunchOverlay .g-btn::before {
//  background: url('../assets/g-line.png') repeat-y;
//  content: '';
//  width: 1px;
//  position: absolute;
//  top: -999px;
//  bottom: 306px;
//  left: 50%;
//}
//.LaunchOverlay .g-btn::after {
//  background: url('../assets/g-line.png') repeat-y;
//  content: '';
//  width: 1px;
//  position: absolute;
//  top: 300px;
//  bottom: -999px;
//  left: 50%;
//} */
.LaunchOverlay .g-btn .bg {
  background: url(../assets/g-btn.png) no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-animation: rot1 10s linear infinite;
  animation: rot1 10s linear infinite;
}
.LaunchOverlay .g-btn {
  font-size: 30px;
  color: #fff;
}
.LaunchOverlay .g-btn .text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.background-media {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
}
</style>
